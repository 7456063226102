import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import arrow from '../../assets/arrow-return.svg';
import Nav from '../nav/nav';

class Header extends Component {

    constructor(props){
        super(props);
        this.state = {
            backURL: this.props.backURL,
            activeNav: false
        };
    }

    activeNav = () => {
        if(this.state.activeNav === true)
            this.setState({ activeNav: false })
        else
            this.setState({ activeNav: true })
    };

    render() {
        return(
            <>
                <Nav activeNav={this.state.activeNav} closeNav={this.activeNav}/>
                <header id="app-header">
                    {this.state.backURL ?
                        <Link className="arrow-return-header" to={this.state.backURL}>
                            <img src={arrow} alt="Arrow icon return" />
                        </Link>
                    : null }
                    <Link to='/' className="logo">
                        <img src={logo} alt="" />
                    </Link>
                    <div className="nav-burger" onClick={() => this.activeNav()}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </header>
            </>
        )
    }
}

export default Header;