import React, { Component } from 'react';
import Header from '../components/header/header';
import { Loader } from '../components/loader/loader';
import Modal from '../components/modal/modal';
import ContentGalleryModal from '../components/gallery-modal/gallery';
import { urlAPI } from '../config/config';
import Tags from '../components/tags/tags';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

import { connect } from 'react-redux';

class DetailReference extends Component{

    constructor(props){
        super(props);
        this.state = { 
            item: {},
            tags: [],
            activeModal: false,
            redirect: false
        };
        this.backURL = '/references';
    }

    componentWillMount(){
        axios.get(`${urlAPI}/wp-json/wp/v2/posts/${this.props.match.params.id}`)
            .then(json => this.setState({ item: json.data }))
            .catch(error => {
                this.setState({ redirect: true });
            })

        axios.get(`${urlAPI}/wp-json/wp/v2/tags/?per_page=100`)
            .then(json => this.setState({ tags: json.data }));
    }

    componentDidMount(){
        document.body.classList.add('detail-ref');
    }

    componentDidUpdate() {
        //TweenMax.fromTo(".enter-project", 1.4, { opacity: 0 }, { opacity: 1 });
    }

    componentWillUnmount(){
        document.body.classList.remove('detail-ref')
    }

    activeModal = () => {
        if(this.state.activeModal === false){
            this.setState({ activeModal: true })
        } else{
            this.setState({ activeModal: false })
        }
    }

    renderItem = () => {
        if(Object.getOwnPropertyNames(this.state.item).length > 0){
            return(
                <main>
                    <div className='enter-project' style={{background: `url(${this.state.item.acf.img_project.url}) no-repeat center / cover`}}>
                    </div>

                    <div className="detail-project">
                        <div className="content">
                            <Tags tags={this.state.tags} tagsItem={this.state.item.tags} />
                            <div className="detail">
                                <h1 className="title-page">
                                    <b>{this.state.item.title.rendered}</b> - {this.state.item.acf.customer}
                                </h1>
                                {this.state.item.acf.context !== '' ? <h3 dangerouslySetInnerHTML={{__html: this.state.item.acf.context}}></h3> : null}
                                <p dangerouslySetInnerHTML={{__html: this.state.item.content.rendered}}></p>
                            </div>
                        </div>
                        {this.state.item.acf.gallery.length > 0 ? 
                            <ContentGalleryModal gallery={this.state.item.acf.gallery} />
                        : null }
                    </div>

                    {this.state.item.acf.pdf_fiche !== false && this.state.item.acf.movie_link !== '' && this.props.offline === false ? 
                        <button onClick={() => this.activeModal()} className="pdf-download">Envoyer la fiche</button> : null 
                    }

                    {this.state.activeModal === true ?
                        <Modal close={this.activeModal} id={this.state.item.id}/> : null
                    }
                </main>
            )
        } else
            return <Loader/>
    }

    render(){
        if(this.state.redirect) {
            return(<Redirect from="/reference/:id" to="/404" />)
        }
        return(
            <>
                <Header backURL={this.backURL} />
                {this.renderItem()}
            </>
        )
    }

}

// export class
const mapStateToProps = state => {
    return {
        test: state.test,
        filterActState: state.filterAct,
        offline: state.offline
    }
}

export default connect(mapStateToProps)(DetailReference);