import React, { Component } from 'react';
import Header from '../components/header/header';
import ItemPosts from '../components/item-posts/item-posts';
import { Loader } from '../components/loader/loader';
import { urlAPI } from '../config/config';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

class References extends Component{

    state = { 
        data: [],
        category: [],
        redirect: false
    };

    constructor(props){
        super(props);
        this.backURL = '/';
    }

    componentWillMount(){
        axios.get(`${urlAPI}/wp-json/wp/v2/technology`)
            .then(json => this.setState({ data: json.data }))
            .catch(error => {
                this.setState({ redirect: true });
            })
    };

    renderProjects = () => {
        if(this.state.data.length > 0) {
            return(
                <main className="container-page">
                    <ItemPosts data={this.state.data} root="technologie"/>
                </main>
            )
        }
        else{
            return <Loader/>
        }
    };

    render(){
        if(this.state.redirect) {
            return(<Redirect from="/technologies" to="/404" />)
        }
        return(
            <>
                <Header backURL={this.backURL}/>
                {this.renderProjects()}
            </>
        )
    };
}

// export class
export default References;