import React, { Component } from 'react';
import { Swipeable } from 'react-swipeable';
import PlayerSvg from '../../assets/player.svg';
import CloseModal from '../../assets/close.svg';
import arrow from '../../assets/arrow-return.svg';
import img_interface from '../../assets/FOND_INTERFACE.jpg';
import Iframe from 'react-iframe';

class ContentGalleryModal extends Component {

    constructor(props){
        super(props);
        this.state = {
            contentModalGalleryStyle: {},
            activeModal: false,
            isPlaying: false,
            index: 0,
            testIndex: null,
            video: false,
            gallery: this.props.gallery,
            window: window.innerWidth,
            left: window.innerWidth
        };

        this.movie = React.createRef();
        this.movieSelected = null;
    }

    arrowDirection = index => {
        if(index > this.state.gallery.length) {
            this.setState({ index: 0, isPlaying: false });
        } else if(index < 0) {
            this.setState({ index: this.state.gallery.length, isPlaying: false });
        } else {
            this.setState({ index: index, isPlaying: false });
        }
    };

    activeModal = (e, index) => {
        e.preventDefault();
        document.getElementsByTagName( 'html' )[0].classList.add('modal-open');

        this.setState({ activeModal: true, index: index });
    };

    closeModal = () => {
        document.getElementsByTagName( 'html' )[0].classList.remove('modal-open');
        this.setState({ activeModal: false, index: 0, isPlaying: false });
    };

    testStyle = () => {
        return {
            width: this.state.window * (this.state.gallery.length + 1),
            left: (this.state.left * this.state.index) * -1
        }    
    }

    componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({
                window: window.innerWidth,
                left: window.innerWidth
            });

            this.testStyle();
        });

        this.movieSelected = document.querySelectorAll('.video-component');
    }

    componentDidUpdate() {
        this.movieSelected.forEach(element => {
            if(this.movieSelected !== null) {
                if(this.state.isPlaying === true && element.classList.contains('is-active')) {
                    element.play();
                    element.controls = true;
                } else {
                    element.pause();
                    element.currentTime = 0;
                    element.controls = false;
                }
            }
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.testStyle);
    }
    
    play = () => {
        this.setState({ isPlaying: true });
    }

    itemMovie = (item, index) => {
        let className = this.state.index === index ? 'video-component is-active' : 'video-component';
        return(
            <>
                {this.state.isPlaying === false ? <button className="btn-video-play" onClick={() => this.play()}>Play</button> : null}
                <video id={index} key={index} width="100%" className={className} ref={this.movie} data-key-movie={index}>
                    { item.video.video_mp4_gallery !== false ? <source crossOrigin="anonymous" src={item.video.video_mp4_gallery.url} type="video/mp4" /> : null}
                    { item.video.video_webm_gallery !== false ? <source crossOrigin="anonymous" src={item.video.video_webm_gallery.url} type="video/webm" /> : null}
                    { item.video.video_ogv_gallery !== false ? <source crossOrigin="anonymous" src={item.video.video_ogv_gallery.url} type="video/ogg" /> : null}
                </video>
            </>
        )
    }



    itemMovieYoutube = item => {
        return (
            <Iframe class="iframe-youtube" url={"https://www.youtube.com/embed/" + item.video_yt_gallery}
                    className="iframe-component"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
            />
        )
    };

    itemPhoto = item => {
        return (<img alt={item.title_gallery} src={item.img_gallery.url} />);
    };
    
    itemIframe = item => {
        return(
            <Iframe url={item.iframe_gallery} height="100%" width="100%" className="iframe-component" allowFullScreen/>
        );
    };

    render(){
        let modalClass = this.state.activeModal === true ? "modal-gallery open" : "modal-gallery";
        if(this.state.gallery !== undefined && this.state.gallery.length > 0) {
            return (
                <>
                <div className="content-gallery">
                    { this.state.gallery.map((data, index) => {
                        return(
                            <div key={index} onClick={(e) => {this.activeModal(e, index)}}>
                                {data.choice_gallery !== 'photo' ? <img src={img_interface} alt='' /> : <img src={data.img_gallery.sizes.large} alt='' />}
                                {data.choice_gallery === 'video' ?
                                    <img src={PlayerSvg} className="picto-player" alt='' /> : null
                                }
                            </div>
                        )
                    })}
                </div>
                <div className={modalClass}>
                    <div className="tools">
                        <div className="pagination">
                            <b>{this.state.index + 1}</b> / {this.state.gallery.length}
                        </div>
                        <button className="close-modal" onClick={() => this.closeModal()}>
                            <img src={CloseModal} alt="close modal"/>
                        </button>
                    </div>

                    { this.state.index > 0  ?
                        <a className="btn-prev-gallery" onClick={() => this.arrowDirection(this.state.index - 1)}>
                            <img src={arrow} alt="arrow"/>
                        </a>
                    : null }

                    { this.state.index < this.state.gallery.length - 1 ?
                        <a className="btn-next-gallery" onClick={() => this.arrowDirection(this.state.index + 1)}>
                            <img src={arrow} alt="arrow"/>
                        </a>
                    : null }

                    <Swipeable
                        className="swipeable-gallery"
                        onSwipedRight={() => this.state.index > 0 ? this.arrowDirection(this.state.index - 1) : null}
                        onSwipedLeft={() => this.state.index < this.state.gallery.length - 1 ? this.arrowDirection(this.state.index + 1) : null} 
                    >
                        <div className={'content-modal'}>
                            <div className={'content-modal-gallery clearfix'} style={this.testStyle()}>
                                { this.state.gallery.map((item, index) => {
                                    let className = this.state.index === index ? 'gallery-item active' : 'gallery-item';
                                    return (
                                        <div ref={this.blocModal} className={className} key={index.toString()}>
                                            {(() => {
                                                switch(item.choice_gallery){
                                                    case 'photo':
                                                        return this.itemPhoto(item)
                                                    case 'video_youtube':
                                                        return this.itemMovieYoutube(item)
                                                    case 'video':
                                                        return this.itemMovie(item, index)
                                                    case 'iframe':
                                                        return this.itemIframe(item)
                                                    default:
                                                        break;
                                                }
                                            })()}
                                            { item.title_gallery ? <div className="description"><p>{item.title_gallery}</p></div>: null }
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Swipeable>
                </div>
                </>
            )
        }
    }
}

export default ContentGalleryModal