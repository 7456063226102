import React, { Component } from 'react';
import Header from '../components/header/header';
import { filterProjects } from '../actions/index';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Home extends Component{

    constructor(props){
        super(props);
        this.backURL = false;
    }
    
    componentDidMount(){
        document.body.classList.add('home');
        this.props.filterAct(null)
    }

    componentWillUnmount(){
        document.body.classList.remove('home');
    }

    top = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    render(){
        return(
            <>
                <Header backURL={this.backURL}/>
                <main className="container-page">
                    <Link to="/elle-et-la" className="category-home" onClick={() => this.top()}>
                        <div className="title">
                            <h4>Elle&la</h4>
                            <button>Découvrir</button>
                        </div>
                    </Link>
                    <Link to="/technologies" className="category-home" onClick={() => this.top()}>
                        <div className="title">
                            <h4>Technologies</h4>
                            <button>Découvrir</button>
                        </div>
                    </Link>
                    <Link to="/offres" className="category-home" onClick={() => this.top()}>
                        <div className="title">
                            <h4>Offres</h4>
                            <button>Découvrir</button>
                        </div>
                    </Link>
                    <Link to="/references" className="category-home" onClick={() => this.top()}>
                        <div className="title">
                            <h4>Références</h4>
                            <button>Découvrir</button>
                        </div>
                    </Link>
                </main>
            </>
        )
    }
}

// export class
const mapStateToProps = state => {
    return {
        filterActState: state.filterAct
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        filterAct: filterProjects 
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);