import React, { Component } from "react";
import { Link } from 'react-router-dom';
import CloseModal from '../../assets/close.svg';
import { TimelineMax, TweenMax } from 'gsap';

export default class Nav extends Component{ 

    constructor(props){
        super(props);
    }

    CloseModal = () => {
        this.props.closeNav();
    };

    componentDidUpdate(){
        if(this.props.activeNav === true){
            const tl = new TimelineMax();
            tl.staggerFromTo(".link-nav-header", 0.7, { opacity: 0, y: -20 }, { opacity: 1, y: 0 }, 0.2);
        }
    }

    nav = () => {
        return(
            <ul>
                <li className="link-nav-header"><Link to="/elle-et-la" onClick={() => this.CloseModal()}>À propos</Link></li>
                <li className="link-nav-header"><Link to="/technologies" onClick={() => this.CloseModal()}>Technologies</Link></li>
                <li className="link-nav-header"><Link to="/offres" onClick={() => this.CloseModal()}>Offres</Link></li>
                <li className="link-nav-header"><Link to="/references" onClick={() => this.CloseModal()}>Références</Link></li>
            </ul>
        )
    };

    render(){
        return(
            <nav id="nav-header" className={this.props.activeNav === true ? "active-nav" : null}>
                <button className="close-modal" onClick={() => this.props.closeNav()}>
                    <img src={CloseModal} alt="close modal"/>
                </button>
                {this.props.activeNav === true ? this.nav() : null}
            </nav>
        )
    }
}