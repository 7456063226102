import React, { Component } from 'react';
import imgFilter from '../../assets/picto_test.png';
import { filterProjects } from '../../actions/index';

// redux 
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Filter extends Component {
    
    constructor(props) {
        super(props);
        this.liFilter = React.createRef();
    }

    filterProject = id => {
        this.liFilter.current.classList.add('test');
        this.props.filterAct(id);
    }

    render() {
        return(
            <div className="filter">
                <ul>
                    <li onClick={() => this.props.filterAct(null)}>
                        <img src={imgFilter} alt="" />
                        <span>All</span>
                    </li>
                    { this.props.category.map((dataFrites, index) => {
                        if(dataFrites.count > 0 && dataFrites.slug !== "non-classe"){
                            return(
                                <li ref={this.liFilter} onClick={() => this.filterProject(dataFrites.id)} key={index}>
                                    {dataFrites.acf.img_category !== undefined ? <img src={dataFrites.acf.img_category.url} alt="" /> : null}
                                    <span>{dataFrites.name}</span>
                                </li>
                            )
                        }
                    })}
                </ul>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        test: state.test,
        filterActState: state.filterAct
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        filterAct: filterProjects 
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Filter);