import React from 'react';
import circle_1 from '../../assets/circle_1.png';
import circle_2 from '../../assets/circle_2.png';
import circle_3 from '../../assets/circle_3.png';

export const Loader = () => {
    return(
        <div className="loader">
            <div className="logo">
                <img className="circle circle-1" src={circle_1}/>
                <img className="circle circle-2" src={circle_2}/>
                <img className="circle circle-3" src={circle_3}/>
            </div>
        </div>
    )
}