import React, { Component } from 'react';
//import LoaderWhite from '../../assets/loader-white.svg';
import CloseModal from '../../assets/close.svg';
import { urlAPI } from '../../config/config';
import { TweenMax } from 'gsap';

class Modal extends Component{

    constructor(){
        super();
        this.state = { 
            valueEmail: '', 
            alert: '',
            statut: false,
            /*loadingForm: false,*/
            db: ''
        }
    }

    handleChange = event => this.setState({ valueEmail: event.target.value })

    componentDidMount(){
        TweenMax.fromTo(".content-modal", 0.8, {y: -40, opacity: 0}, {y: 0, opacity: 1});
    }

    submitForm = e => {
        e.preventDefault();
        this.setState({ loadingForm: true });

        const button = document.querySelector('.button-send-form');
        button.setAttribute('disabled', true);

        fetch(`${urlAPI}/wp-json/wp/v2/send-mail/?email=${this.state.valueEmail}&id=${this.props.id}`)
            .then(data => data.json())
            .then(json => {
                if(json.statut === 200){
                    this.setState({
                        alert: 'Merci pour votre message. Votre mail est envoyé',
                        statut: true,
                        valueEmail: '',
                        loadingForm: false
                    });
                    button.removeAttribute('disabled');
                } else{
                    this.setState({
                        alert: "Votre mail ne parvient pas à s'envoyer. Veuillez réessayer.",
                        statut: false,
                        valueEmail: '',
                        loadingForm: false
                    })
                    button.removeAttribute('disabled');
                }
            })
            .catch(error => console.log(error))
    }

    render(){
        return(
            <div id="modal-contact" className="modal">
                <button className="close-modal" onClick={() => this.props.close()}>
                    <img src={CloseModal} alt="close modal"/>
                </button>
                <div className="content-modal">
                    <p className="title-modal">Vous souhaitez envoyer la fiche descriptive de ce projet ?</p>
                    <form onSubmit={this.submitForm}>
                        <input type='email' placeholder="Adresse mail" value={this.state.valueEmail} onChange={this.handleChange}/>
                        <button type="submit" className="button-send-form">
                            {/*this.state.loadingForm === true ? <img src={LoaderWhite} alt="Loader" /> : null*/}
                            {this.state.loadingForm === true ? 'Envoi en cours…' : 'Envoyer'}
                        </button>
                        {this.state.alert !== '' ? <p style={{ color: this.state.statut === true ? "#2ecc71" : "#e74c3c", textAlign: 'left'}}>{this.state.alert}</p> : null}
                    </form>
                </div>
            </div>
        )
    }

}

export default Modal;