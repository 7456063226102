import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import * as serviceWorker from './serviceWorker';
import './index.css';
import rootReducer from './reducers/index';
import App from './App';

serviceWorker.unregister();

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
    navigator.serviceWorker.register('service-worker-custom.js')
    .then(function() {
      console.log('active service worker');
    })
    .catch(function() {
      console.log('service worker no actif');
    })
  });
}

const createStoreWithMiddleware = applyMiddleware()(createStore);

ReactDOM.render(
 <Provider store={createStoreWithMiddleware(rootReducer,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())}>  
    <App />
  </Provider>
  , document.querySelector('#root'));