import React, {Component} from 'react';
import { Link } from 'react-router-dom';
//import { TweenMax } from 'gsap';

import { connect } from 'react-redux';

const top = () => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0 
}

class Item extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount(){
        //TweenMax.staggerFromTo(".category-home", 0.3, { opacity: 0 }, { opacity: 1 }, 0.2);
    }

    render(){
        return(
            this.props.data.map(data => {
                if(this.props.filterActState === null){
                    return(
                        <Link key={data.id} to={`${this.props.root}/${data.id}`}
                            className="category-home"
                            style={{background: `url(${data.acf.img_project.url}) no-repeat center / cover`}}
                            onClick={() => top()}>
                            <div className="title">
                                <h4>{data.title.rendered}</h4>
                                <button>En savoir +</button>
                            </div>
                        </Link>
                    );
                } else if(this.props.filterActState !== null && data.categories.includes(this.props.filterActState) === true){
                    return(
                        <Link key={data.id} to={`${this.props.root}/${data.id}`}
                            className="category-home"
                            style={{background: `url(${data.acf.img_project.url}) no-repeat center / cover`}}
                            onClick={() => top()}>
                            <div className="title">
                                <h4>{data.title.rendered}</h4>
                                <button>En savoir +</button>
                            </div>
                        </Link>
                    );
                }
            })
        )
    }
}

const mapStateToProps = state => {
    return {
        filterActState: state.filterAct
    }
}

export default connect(mapStateToProps)(Item);