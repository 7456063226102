import React, { Component } from 'react';
import Header from '../components/header/header';
import { Loader } from '../components/loader/loader';
import Modal from '../components/modal/modal';
import ContentGalleryModal from '../components/gallery-modal/gallery';
import { urlAPI } from '../config/config';
import { TweenMax } from 'gsap';
import Tags from '../components/tags/tags';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

class DetailOffers extends Component{

    constructor(){
        super();
        this.state = { 
            item: {},
            tags: [],
            activeModal: false,
            backURL: '/offres',
            redirect: false
        };
    }

    componentWillMount(){
        axios.get(`${urlAPI}/wp-json/wp/v2/offer/${this.props.match.params.id}`)
            .then(json => this.setState({ item: json.data }))
            .catch(error => {
                this.setState({ redirect: true });
            })

        axios.get(`${urlAPI}/wp-json/wp/v2/tags-offers`)
            .then(tags => this.setState({ tags: tags.data }))
            .catch(error => {
                this.setState({ redirect: true });
            })
    }

    componentDidMount(){
        document.body.classList.add('detail-ref')
    }

    componentDidUpdate(){
        TweenMax.fromTo(".enter-project", 1.4, { opacity: 0 }, { opacity: 1 });
    }

    componentWillUnmount(){
        document.body.classList.remove('detail-ref')
    }

    activeModal = () => {
        if(this.state.activeModal === false){
            this.setState({ activeModal: true })
        } else{
            this.setState({ activeModal: false }) 
        }
    }

    renderItem = () => {
        if(Object.getOwnPropertyNames(this.state.item).length > 0){
            return(
                <main>
                    <div className='enter-project' style={{background: `url(${this.state.item.acf.img_project.url}) no-repeat center / cover`}}>
                    </div>

                    <div className="detail-project">
                        <div className="content">
                            <Tags tags={this.state.tags} tagsItem={this.state.item.post_tags} type="cpt" />
                            <div className="detail">
                                <h1 className="title-page">
                                    <b>{this.state.item.post_title}</b>
                                </h1>
                                {this.state.item.acf.context !== false ? <h3 dangerouslySetInnerHTML={{__html: this.state.item.acf.context}}></h3> : null}
                                <p dangerouslySetInnerHTML={{__html: this.state.item.post_content}}></p>
                            </div>
                        </div>
                        {this.state.item.acf.gallery.length > 0 ? <ContentGalleryModal gallery={this.state.item.acf.gallery}/> : null}
                    </div>

                    {this.state.item.acf.pdf_fiche !== false && this.state.item.acf.movie_link !== '' ? 
                        <button onClick={() => this.activeModal()} className="pdf-download">Envoyer la fiche</button> : null 
                    }

                    {this.state.activeModal === true ?
                        <Modal close={this.activeModal} id={this.state.item.ID}/> : null
                    }
                </main>
            )
        } else
            return <Loader/>
    }

    render(){
        if(this.state.redirect) {
            return(<Redirect from="/offre/:id" to="/404" />)
        }
        return(
            <>
                <Header backURL={this.state.backURL} />
                {this.renderItem()}
            </>
        )
    }

}

// export class
export default DetailOffers;