import React, { Component } from 'react';
import Header from '../components/header/header';
import { Footer } from '../components/footer/footer';
import { urlAPI } from '../config/config';
import { Loader } from '../components/loader/loader';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

class About extends Component{

    state = {
        data: false,
        redirect: false
    };

    constructor(props){
        super(props);
        this.backURL = '/';
    }

    async componentWillMount(){
        const getData = await axios.get(`${urlAPI}/wp-json/wp/v2/about`)
            .then(response => response.data)
            .catch(error => {
                this.setState({ redirect: true });
            })

        this.setState({ data: getData })
    }

    render(){
        if(this.state.redirect) {
            return(<Redirect from="/elle-et-la" to="/404" />)
        }
        const data = this.state.data;
            return (
                <React.Fragment>
                    <Header backURL={this.backURL}/>
                    { this.state.data !== false ? 
                        <div id='about-section'>
                            <div className={'entry-header'}>
                                <h2 className={'general-title'}>
                                    {data.general_title}
                                </h2>
                                <section className={'intro-text'}>
                                    <p dangerouslySetInnerHTML={{ __html: data.text_intro }}></p>
                                </section>
                            </div>
                            <section className={'entry-expertise'}>
                                <div className={'content-background-image'} style={{backgroundImage: 'url(' + data.section_expertises.img_section.url + ')'}}>
                                </div>
                                <div className={'entry-content'}>
                                    <h3 className={'entry-title'}>
                                        {data.section_expertises.title_section}
                                    </h3>
                                    <div className={'content-expertises clearfix'}>
                                        {data.section_expertises.bloc_picto.map((data, index) => {
                                            return(
                                                <div key={index} className={'expertise-block col-'+ data.columns}>
                                                    <img src={data.picto.url} className={'thumbnail'}/>
                                                    <div>
                                                        <h3 className={'expertise-title'}>{ data.title_bloc_picto }</h3>
                                                        <p className={'expertise-description'} dangerouslySetInnerHTML={{ __html: data.subtitle_bloc_picto }}></p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </section>

                            <section className={'entry-methodology'}>
                                <div className={'entry-content'}>
                                    <h3 className={'entry-title'}>{data.section_methodology.title_section}</h3>
                                    <div className={'entry-description'} dangerouslySetInnerHTML={{ __html: data.section_methodology.text_section}}></div>
                                </div>
                                <div className={'relative'}>
                                    <div className={'content-methodology'}>
                                        {data.section_methodology.bloc_picto.map((data, index) => {
                                            return(
                                                <div key={index} className={'item-methodology'}>
                                                    <img src={data.picto.url} />
                                                    <h3 className={'entry-title'} dangerouslySetInnerHTML={{ __html: data.title_bloc_picto}}></h3>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className={'content-background-image'} style={{backgroundImage: 'url(' + data.section_methodology.img_section.url + ')'}}>
                                    </div>
                                </div>
                            </section>

                            <section className={'entry-group'}>
                                <h3 className={'entry-title'} dangerouslySetInnerHTML={{ __html: data.section_group.title_section}}></h3>
                                <div className={'entry-content'} dangerouslySetInnerHTML={{ __html: data.section_group.text_section}}></div>
                                <img className={'thumbnail'} src={data.section_group.img_group.url}/>
                            </section>
                        </div>
                 : <Loader/> }
                 { this.state.data !== false ? <Footer/> : null}
            </React.Fragment>
            );
    }
    
}

// export class
export default About;