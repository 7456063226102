export const PROJECTS_FILTER = "PROJECTS_FILTER";
export const OFFLINE = "OFFLINE";

export const filterProjects = data => {
   return {
      type: PROJECTS_FILTER,
      payload: data 
   }
}

export const offline = data => {
   return {
      type: OFFLINE,
      payload: data
   }
}