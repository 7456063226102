import React, { Component } from 'react';
import Header from '../components/header/header';
import Filter from '../components/filter/filter';
import Item from '../components/item/item';
import { Loader } from '../components/loader/loader';
import { urlAPI } from '../config/config';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

class References extends Component{

    constructor(props){
        super(props);
        this.backURL = '/';
        this.state = { 
            data: [],
            category: [],
            valueFilter: '#0',
            redirect: false
        };
    }

    componentWillMount(){
        axios.get(`${urlAPI}/wp-json/wp/v2/posts/?per_page=100`)
            .then(json => this.setState({ data: json.data }))
            .catch(error => {
                this.setState({ redirect: true });
            })

        axios.get(`${urlAPI}/wp-json/wp/v2/categories`)
            .then(json => this.setState({ category: json.data }))
            .catch(error => {
                this.setState({ redirect: true });
            })
    }

    renderCategory = () => {
        if(this.state.data.length > 0) {
            return <Filter category={ this.state.category } />
        }
    }

    renderProjects = () => {
        if(this.state.data.length > 0) {
            return(
                <main className="container-page">
                    <Item data={this.state.data} root="reference" valueFilter={window.location.hash}/>
                </main>
            );
        } else {
            return <Loader/>
        }
    };

    value = value => {
        window.location.hash = value;
        this.setState({ valueFilter: `#${value}` })
    }

    render(){
        if(this.state.redirect) {
            return(<Redirect from="/references" to="/404" />)
        }
        return(
            <>
                <Header backURL={this.backURL} />
                { this.renderCategory() }
                { this.renderProjects() }
            </>
        )
    }
}

// export class
export default References;