import React from 'react';

const Tags = props => {
    if(props.type === "cpt"){
        return(
            <ul className="tag">
                {props.tags.map(tags => {
                    var tagsProject = props.tagsItem ? props.tagsItem.findIndex(data => data.term_id === tags.term_id) : -1;
                    return tagsProject !== -1 ? <li key={tags.term_id}>{tags.name}</li> : null
                })}
            </ul>
        )
    } else {
        return(
            <ul className="tag">
                {props.tags.map(tags => {                       
                    var tagsProject = props.tagsItem.findIndex(data => data === tags.id);
                    return tagsProject !== -1 ? <li key={tags.id}>{tags.name}</li> : null
                })}
            </ul>
        )
    }
}

// export component
export default Tags;