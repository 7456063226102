import React, { Component } from 'react';
import Header from '../components/header/header';

class NoMatch extends Component{

    constructor(props){
        super(props);
        this.backURL = false;
    }

    render(){
        return(
            <>
                <Header backURL={this.backURL}/>
                <main className="container-page">
                    <h1 style={{textAlign: 'center', paddingTop: '40px'}}>
                        Ce contenu n’a pas été correctement pré-chargé : <br/>
                        veuillez vous reconnecter au Wifi pour y accéder
                    </h1>
                </main>
            </>
        )
    }
}

export default NoMatch;