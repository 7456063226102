import React, { Component } from 'react';
import Header from '../components/header/header';
import { Loader } from '../components/loader/loader';
import ContentGalleryModal from '../components/gallery-modal/gallery';
import { urlAPI } from '../config/config';
import { TweenMax } from 'gsap';
import Tags from '../components/tags/tags';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

class DetailTechnology extends Component{

    constructor(){
        super();
        this.state = { 
            item: {},
            tags: [],
            redirect: false
        };
        this.backURL = '/technologies';
    }

    componentWillMount(){
        axios.get(`${urlAPI}/wp-json/wp/v2/technology/${this.props.match.params.id}`)
            .then(json => this.setState({ item: json.data }))
            .catch(error => {
                this.setState({ redirect: true });
            })

        axios.get(`${urlAPI}/wp-json/wp/v2/tags-technology`)
            .then(json => this.setState({ tags: json.data }))
    }

    componentDidUpdate(){
        TweenMax.fromTo(".enter-project", 1.4, { opacity: 0 }, { opacity: 1 });
    }

    activeModal = () => {
        if(this.state.activeModal === false){
            this.setState({ activeModal: true })
        } else{
            this.setState({ activeModal: false })
        }
    }

    renderItem = () => {
        if(Object.getOwnPropertyNames(this.state.item).length > 0){
            return(
                <main>
                    <div className='enter-project' style={{background: `url(${this.state.item.acf.img_project.url}) no-repeat center / cover`}}>
                    </div>
                
                    <div className="detail-project">
                        <div className="content">
                            <Tags tags={this.state.tags} tagsItem={this.state.item.post_tags} type="cpt" />
                            <div className="detail">
                                <h1 className="title-page"><b>{this.state.item.post_title}</b></h1>
                                {this.state.item.acf.context !== false ? <h3 dangerouslySetInnerHTML={{__html: this.state.item.acf.context}}></h3> : null}
                                <p dangerouslySetInnerHTML={{__html: this.state.item.post_content}}></p>
                            </div>
                        </div>
                        {this.state.item.acf.gallery.length > 0 ? <ContentGalleryModal gallery={this.state.item.acf.gallery}/> : null}
                    </div>
                </main>
            )
        } else
            return <Loader/>
    }

    render(){
        if(this.state.redirect) {
            return(<Redirect from="/technologie/:id" to="/404" />)
        }
        return(
            <>
                <Header backURL={this.backURL}/>
                {this.renderItem()}
            </>
        )
    }

}

// export class
export default DetailTechnology;