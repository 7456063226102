import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { TweenMax } from 'gsap';
import { Detector } from "react-detect-offline";
import Home from './pages/home';
import About from './pages/about';
import Technologies from './pages/technologies';
import DetailTechnology from './pages/detail-technology';
import DetailReference from './pages/detail-references';
import DetailOffers from './pages/detail-offers';
import Offres from './pages/offres';
import References from './pages/references';
import OfflinePages from './pages/offline';
import NoMatch from './pages/404';

class App extends Component {
  
  componentDidMount(){
    TweenMax.fromTo(".App", 1, { opacity: 0 }, { opacity: 1 });
  }

  render() {
    return (
        <div className="App">
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/elle-et-la" component={About} />
              <Route exact path="/offres" component={Offres} />
              <Route exact path="/references" component={References} />
              <Route exact path="/reference/:id" component={DetailReference} />
              <Route exact path="/technologie/:id" component={DetailTechnology} />
              <Route exact path="/offre/:id" component={DetailOffers} />
              <Route exact path="/technologies" component={Technologies} />
              <Route exact path="/offline" component={OfflinePages} />
              <Route path='*' component={NoMatch} />
            </Switch>
          </BrowserRouter>

          <Detector render={({ online }) => (
              <div className={online ? 'offline' : 'offline active'}>Aucune connexion internet</div>
          )}/>
      </div>
    );
  }
}

// export class
export default App;