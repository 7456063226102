import React, {Component} from 'react';
import { Link } from 'react-router-dom';
//import { TweenMax } from 'gsap';

const top = () => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0 
}

class ItemPosts extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount(){
        //TweenMax.staggerFromTo(".category-home", 0.3, { opacity: 0 }, { opacity: 1 }, 0.2);
    }

    render(){
        return(
            this.props.data.map(data => {
                return(
                    <Link key={data.id} to={`${this.props.root}/${data.id}`}
                        className="category-home"
                        style={{background: `url(${data.acf.img_project.url}) no-repeat center / cover`}}
                        onClick={ () => top() }>
                            <div className="title">
                                <h4>{data.title.rendered}</h4>
                                <button>En savoir +</button>
                            </div>
                    </Link>
                )
            })
        )
    }
}

export default ItemPosts;