import React, { Component } from 'react';
import Header from '../components/header/header';

class OfflinePage extends Component{

    constructor(props){
        super(props);
        this.backURL = false;
    }

    render(){
        return(
            <>
                <Header backURL={this.backURL}/>
                <main className="container-page">
                    Offline
                </main>
            </>
        )
    }
}

export default OfflinePage;